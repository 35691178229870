export const educationData = [
    {
        id: 1,
        institution: 'South Industrial Management Institute',
        course: 'Network+',
        startYear: 'May 2016',
        endYear: 'June 2016'
    },
    {
        id: 2,
        institution: 'South Industrial Management Institute',
        course: 'MCSA,CCNA,MTCNA ',
        startYear: 'January 2017',
        endYear: 'February 2018'
    },
    {
        id: 3,
        institution: 'South Industrial Management Institute',
        course: 'Java SE',
        startYear: 'May 2018',
        endYear: 'June 2018'
    },
    {
        id: 4,
        institution: 'South Industrial Management Institute',
        course: 'Android Development',
        startYear: 'July 2019',
        endYear: 'November 2019'
    },
]