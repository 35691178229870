export const achievementData = {
    bio : "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers.",
    achievements : [
        {
            id : 1,
            title : 'Linguaskill General',
            details : 'Linguaskill assesses English language ability from below A1 to C1 or above of the Common European Framework of Reference (CEFR). For each skill assessed, candidates are awarded a CEFR level and a score on the Cambridge English Scale. My CEFR level is B2.',
            date : '30 April 2023',
            field : 'Cambridge English Exam',
            image : 'https://mrvian.com/wp-content/uploads/2022/10/cambridge-coat-of-arm.png'
        },
        // {
        //     id : 2,
        //     title : 'AWS Certification',
        //     details : 'Validate technical skills and cloud expertise to grow your career and business.',
        //     date : 'Aug 20, 2019',
        //     field : 'Automation',
        //     image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        // },
        // {
        //     id : 3,
        //     title : 'Professional Data Engineer Certification',
        //     details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
        //     date : 'Aug 20, 2019',
        //     field : 'Automation',
        //     image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        // }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/