import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";
import netshield from "../assets/svg/projects/netshield.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "Employee Management",
    projectDesc:
      "This project aims to build an employee management system that consolidates all information of a company",
    tags: ["Java", "Android", "Material Ui"],
    image: one,
  },
  {
    id: 2,
    projectName: "Netshield Full Fledged Ad Blocker",
    projectDesc:
      "Net Shield is the best ad blocker. Unlike other ad blockers, you do not need to use a special browser to block ads and do not need root access. Net Shield works in any application and browser installed on your device.",
    tags: ["Php", "Flutter", "Dart", "Material Ui"],
    code: "",
    demo: "https://netshield.ir/",
    image: netshield,
  },
  {
    id: 3,
    projectName: "Weather App",
    projectDesc:
      "Weather forecast systems and applications predict weather conditions based on multiple parameters.",
    tags: ["Java", "php","andorid", "Material Ui"],
    image: three,
  },
  {
    id: 4,
    projectName: "Android Patient Tracker MpMed",
    projectDesc:
      "This project involves the complete eco system of web and mobile application for tracking patient data and intraction with corresponding doctor.",
    tags: ["php", "dart", "Flutter", "Mysql", "Firebase", "Material Ui"],
    demo: "https://patient.mpmed.ir",
    image: four,
  },
  {
    id: 5,
    projectName: "E-Commerce Web App",
    projectDesc: "An Inerior decoration web site",
    tags: ["wordpress", "Firebase"],
    demo: "https://homenet.ir/",
    image: five,
  },
  {
    id: 6,
    projectName: "Stock Market App",
    projectDesc: "A simple stock market API app",
    tags: ["React", "Redux", "Bootstrap"],
    image: seven,
  },
  {
    id: 8,
    projectName: "Car Pooling System",
    projectDesc:
      "The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution",
    tags: ["Flutter", "React"],
    image: eight,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
