export const socialsData = {
    github: 'https://github.com/SavvyEngineer',
    instagram: 'https://www.instagram.com/khashayar__kazemi',
    mail:"mailto:khashayar.kazemi.dev@gmail.com",
    phone:"tel:+989033037618",
    linkedIn:"https://www.linkedin.com/in/khashayar-kazemi-32983224a"
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}