export const experienceData = [
    {
        id: 1,
        company: 'Proxify, Stockholm (Remote)',
        jobtitle: 'Senior Systems Infrastructure,Mobile App Developer and Backend Architect',
        startYear: 'October 2022 ',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Sustainable Universal Golden Arc Co. (UGA), Shiraz',
        jobtitle: 'Web Developer and Backend Architect',
        startYear: 'August 2021',
        endYear: 'August 2022'
    },
    {
        id: 3,
        company: 'Amvaj Nouri Co. (Miz Pezeshk), Shiraz - Bushehr',
        jobtitle: 'Mobile App Developer and Systems Infrastructure Developer',
        startYear: 'October 2019',
        endYear: 'January 2021'
    },
    {
        id: 4,
        company: 'Mehragan Agriculture Cooperative Company (RiraTrade), Abdan',
        jobtitle: 'Senior Software Engineer and Backend Designer',
        startYear: 'March 2018',
        endYear: 'June 2019'
    },
    {
        id: 5,
        company: 'Building Design Engineering Office (IzeeTech), Shiraz',
        jobtitle: 'Web Developer,Mobile App Developer and Backend Architect',
        startYear: 'July 2015',
        endYear: 'June 2017'
    },
    {
        id: 6,
        company: 'Kazemi Law Office, Tehran',
        jobtitle: 'Mobile App and Backend Developer',
        startYear: 'April 2014',
        endYear: 'June 2015'
    }
]