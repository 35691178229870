import resume from '../assets/pdf/resume.pdf'
import profileImage from '../assets/png/me.png'

export const headerData = {
    name: 'khashayar kazemi',
    title: "Software Developer",
    desciption:"There wasn't a bird in the sky, but that was not what caught his attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. He knew what was coming and he hoped he was prepared. ",
    image: profileImage,
    resumePdf: resume
}
